import React from 'react'
import { Link } from 'gatsby'


import facebook from '../img/social/facebook.svg'
/*
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'
*/
import "./layout.css"

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          {/*  <img
            src={logo}
            alt="Ekochron"
            style={{ width: '18em', height: '6em' }}
          /> */}
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns footherColumns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/ocynkownia" className="navbar-item">
                        OCYNKOWNIA
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/jakosc">
                        JAKOŚĆ
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/poradniki">
                        PORADNIKI
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        O NAS
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        AKTUALNOŚCI
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/instrukcje">
                        INSTRUKCJE
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        KONTAKT
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/certyfikaty">
                        CERTYFIKATY
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://www.facebook.com/ekochron/">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                {/* Media społecznościowe
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a> */}

              </div>
            </div>
            <a id="tworca" href="mailto:krystianhutyra@gmail.com">
              Twórca: Krystian Hutyra
                  </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
